














































































































































































































































































































import { Api, DictionaryApprovalOption, DictionaryInfo, DictionaryOwnerOption, DictionaryVisibilityOption, isAxiosError, Locale, PagedResults } from '@/edshed-common/api'
import EditWordDataModal from '@/edshed-common/components/dictionary/EditWordDataModal.vue'

import { Howl } from 'howler'
import { isEqual, cloneDeep, debounce } from 'lodash'
import { Vue, Component, Mixins } from 'vue-property-decorator'

import { LocaleData } from '@/edshed-common/i18n'
import axios from 'axios'
import ComponentHelper from '@/mixins/ComponentHelper'
import FindListsForWord from './components/FindListsForWord.vue'
import DictionaryMergeTool from './components/DictionaryMergeTool.vue'

@Component({ components: { EditWordDataModal, FindListsForWord, DictionaryMergeTool } })
export default class Dictionary extends Mixins(ComponentHelper) {
  private wordsData: PagedResults<DictionaryInfo> = { items: [], total: 0 }

  private editingData: DictionaryInfo | null = null

  private page: number = 1
  private sort: string = 'word'
  private order: 'asc' | 'desc' = 'asc'

  private searchPhrase: string = ''
  private searchedPhrase: string = ''

  private searchLocale: Locale = 'en_GB'

  private loading: boolean = false

  private dictionaryMergePrimary: DictionaryInfo | null = null
  private dictionaryMergeSecondary: DictionaryInfo | null = null

  private selectedVisibility: DictionaryVisibilityOption[] = ['public']
  private selectedOwner: DictionaryOwnerOption[] = ['edshed']
  private selectedApproval: DictionaryApprovalOption[] = ['approved', 'unapproved']

  dictionaryIdToSearch: number | null = null

  debounce = debounce

  cloneDeep = cloneDeep

  isEqual = isEqual

  DictionaryVisibilityOption = DictionaryVisibilityOption

  DictionaryOwnerOption = DictionaryOwnerOption

  DictionaryApprovalOption = DictionaryApprovalOption

  mounted () {
    if (!this.$store.state.user.superuser && !this.$store.state.user.dictionary_editor) {
      this.$router.push('/noaccess')
    } else {
      this.getDictionaryData()
    }
  }

  searchDidChange () {
    this.page = 1
    this.getDictionaryData()
  }

  setPagingDebounced = debounce((page) => {
    this.setPaging(page)
  }, 500)

  setPaging (page: string) {
    const pageInt = parseInt(page, 10)

    if (!isNaN(pageInt) && pageInt > 0) {
      this.page = pageInt
      this.getDictionaryData()
    }
  }

  onPageChange (page) {
    this.page = page
    this.getDictionaryData()
  }

  onSortChange (sort: string, order: 'asc' | 'desc') {
    this.sort = sort
    this.order = order
    this.getDictionaryData()
  }

  async wordsMerged () {
    this.dictionaryMergePrimary = null
    this.dictionaryMergeSecondary = null

    await new Promise(resolve => setTimeout(resolve, 200))

    this.getDictionaryData()
  }

  pageSize = 10

  async getDictionaryData () {
    console.log('data')
    this.wordsData = await Api.getDictionaryData({ skip: (this.page - 1) * this.pageSize, take: this.pageSize, dir: this.order, term: this.searchPhrase, sort: this.sort },
      {
        locale: this.searchLocale,
        parent_id: null,
        visibility: this.selectedVisibility,
        owner: this.selectedOwner,
        approval: this.selectedApproval
      })
    this.searchedPhrase = this.searchPhrase
  }

  addWord (word: string) {
    this.editingData = {
      id: 0,
      word,
      word_class: null,
      parent_word: null,
      user_id: 1,
      locale: this.searchLocale,
      errors: [],
      definitions: [],
      difficulty_index: 0,
      morphemes: [],
      syllables: [],
      sentences: [],
      phonics: [],
      synonyms: [],
      antonyms: [],
      image: null,
      audio: null,
      flags: [],
      approved: false,
      hidden: false,
      as_in: null,
      elements: [],
      ipa_definition: null,
      variant_type: null,
      variant_phonics: null,
      variant_audio: null
    }
  }

  sentencify (sen, word) {
    const rep = `<span class="has-text-primary"><b>${word}</b></span>`
    return sen.split('*').join(rep)
  }

  async playSoundForPhoneme (code: string, locale: LocaleData) {
    if (!code) {
      return
    }

    // TODO: maybe create a phonics audio override in locale-map
    if (locale.underscored === 'en_NZ') {
      locale.underscored = 'en_AU'
    }
    if (locale.underscored === 'en_IE') {
      locale.underscored = 'en_GB'
    }

    const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

    await axios.get(filename, { headers: [], withCredentials: false })
    await axios.get(oggFile, { headers: [], withCredentials: false })

    const sound = new Howl({
      src: [oggFile, filename]
    })

    sound.play()
  }

  async hideDictionaryRow (dictionary: DictionaryInfo) {
    await Api.makeDictionaryWordHidden(dictionary.id)

    // delay to let db settle
    await new Promise(resolve => setTimeout(resolve, 200))

    return this.getDictionaryData()
  }

  deleteDictionaryRow (dictionary: DictionaryInfo) {
    this.$buefy.dialog.confirm({
      title: 'Delete Word',
      message: 'Are you sure you want to delete this definition? <br>It will be removed from all lists and game play history<br>This action cannot be undone.',
      confirmText: 'Delete Word',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        this.loading = true

        try {
          await Api.deleteDictionaryWord(dictionary.id)

          this.$buefy.toast.open({
            message: 'Word deleted',
            type: 'is-success',
            position: 'is-bottom',
            duration: 3000
          })

          await new Promise(resolve => setTimeout(resolve, 200))

          return this.getDictionaryData()
        } catch (err: unknown) {
          if (isAxiosError(err)) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 3000
            })
          }
        } finally {
          this.loading = false
        }
      }
    })
  }

  async duplicateDictionary (id: number) {
    try {
      this.loading = true
      const res = await Api.duplicateDictionaryWord(id)
      this.wordsData.items.push(res)
      this.loading = false
      this.$buefy.toast.open({ type: 'is-success', message: 'Word duplicated successfully!', position: 'is-bottom' })
    } catch (err: unknown) {
      this.$buefy.toast.open({ type: 'is-danger', message: 'Could not duplicate the word!', position: 'is-bottom' })
    } finally {
      this.loading = false
    }
  }
}

