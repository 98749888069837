

































































import { Api, DictionaryInfo } from '@/edshed-common/api'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({
  name: 'DictionaryMergeTool'
})
export default class DictionaryMergeTool extends Mixins(ComponentHelper) {
  @Prop({ required: true }) primary!: DictionaryInfo
  @Prop({ required: true }) secondary!: DictionaryInfo

  direction: boolean = true

  loadingScreen: { close: () => any } | null = null

  close () {
    this.$emit('close')
  }

  closeWithSuccess () {
    this.$emit('success')
  }

  toggleDirection () {
    this.direction = !this.direction
  }

  showLoadingScreen () {
    this.loadingScreen = this.$buefy.loading.open({})
  }

  closeLoadingScreen () {
    this.loadingScreen?.close()
    this.loadingScreen = null
  }

  doMerge () {
    this.$buefy.dialog.confirm({
      title: 'Merging Words',
      message: 'Are you sure you want to merge these definitions? <br>Word <strong>"' + (this.direction ? this.primary.word : this.secondary.word) + '"</strong> and the associated data will be kept.<br>This action cannot be undone.',
      confirmText: 'Merge Words',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        this.showLoadingScreen()
        console.log('MERGE')

        try {
          await Api.mergeDictionaryWords(this.direction ? this.primary.id : this.secondary.id, this.direction ? this.secondary.id : this.primary.id)

          this.closeLoadingScreen()
          this.closeWithSuccess()
        } catch (err) {
          this.closeLoadingScreen()
        }
      }
    })
  }
}
