






































































































































import { Api, Locale, PagedResults, SchoolModel, SimpleSchool, SpellingList, TableState, UserRoles } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { debounce } from 'lodash'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'FindListsForWord'
})
export default class FindListsForWord extends Mixins(ComponentHelper) {
  @Prop({ required: true }) dictionaryId!: number

  lists: PagedResults<SpellingList> = {
    total: 0,
    items: []
  }

  tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: '',
    dir: 'asc',
    term: ''
  }

  loadingTableData: boolean = false

  private schoolSearchTerm: string = ''
  private schoolSearchResults: SimpleSchool[] = []
  private selectedSchool: SchoolModel | null = null

  private selectedUserRoles: UserRoles[] = []

  private localeFilter: Locale | null = null

  @Watch('selectedSchool')
  onSelectedSchoolChanged (val: SchoolModel | null) {
    if (val) {
      this.loadTableData()
    } else {
      this.schoolSearchResults = []
      this.loadTableData()
    }
  }

  @Watch('selectedUserRoles')
  onSelectedRolesChanged () {
    this.loadTableData()
  }

  @Watch('localeFilter')
  onSelectedLocaleChanged () {
    this.loadTableData()
  }

  mounted () {
    if (!this.dictionaryId) {
      return
    }
    this.loadTableData()
  }

  async loadTableData () {
    try {
      this.loadingTableData = true
      this.lists = await Api.getListsForDictionaryId(this.dictionaryId,
        {
          school_id: this.selectedSchool?.id || undefined,
          user_roles: this.selectedUserRoles.length > 0 ? this.selectedUserRoles : undefined,
          locale: this.localeFilter || undefined
        },
        { skip: (this.tableState.page - 1) * this.tableState.perPage, take: this.tableState.perPage })
    } finally {
      this.$nextTick(() => {
        this.loadingTableData = false
      })
    }
  }

  searchSchoolsDebounced = debounce((school) => {
    this.searchSchools(school)
  }, 500)

  async searchSchools (school: string) {
    if (school.length < 3) {
      this.schoolSearchResults = []
      return
    }

    try {
      this.schoolSearchResults = await Api.searchSchools(school, false)
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Error searching schools',
        type: 'is-danger'
      })

      console.log(err)
    }
  }

  onPageChange (page: number) {
    this.tableState.page = page

    this.loadTableData()
  }

  viewList (ident) {
    window.open(`${this.config.targetInfo.auth}lists/${ident}`)
  }
}
